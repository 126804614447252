// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-information-js": () => import("./../../../src/pages/cookie-information.js" /* webpackChunkName: "component---src-pages-cookie-information-js" */),
  "component---src-pages-experiences-vouchers-js": () => import("./../../../src/pages/experiences-vouchers.js" /* webpackChunkName: "component---src-pages-experiences-vouchers-js" */),
  "component---src-pages-house-rules-js": () => import("./../../../src/pages/house-rules.js" /* webpackChunkName: "component---src-pages-house-rules-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-recording-js": () => import("./../../../src/pages/recording.js" /* webpackChunkName: "component---src-pages-recording-js" */),
  "component---src-pages-rehearsal-js": () => import("./../../../src/pages/rehearsal.js" /* webpackChunkName: "component---src-pages-rehearsal-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */)
}

